import React from 'react';
import {MailIcon, } from '@heroicons/react/outline'
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="flex min-h-screen flex-col justify-center overflow-hidden bg-gray-50 py-6 sm:py-12">
        <div className="bg-white px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-2xl sm:rounded-lg sm:px-10 hover:-translate-y-1 hover:shadow-2xl transition duration-300 ease-in-out">
          <div className="flex items-center">
            <img src="https://avatars.githubusercontent.com/u/37106672?v=4" className="w-24 h-24 rounded-full right-0 bottom-0 mr-6" alt="avatar" />
            <div className='grid justify-items-start ml-2'>
              <p className='text-3xl text-slate-800 font-semibold'>Seokhyeon Park</p>
              <p className='flex text-lg text-sky-500 ml-[0.1rem]'>Ph.D. Student @ SNU HCI Lab</p>
            </div>
          </div>
          <div className="text-left mt-8 leading-relaxed flex flex-col gap-1">
            <p className='text-xl mb-1 '>Hi 👋</p>
            <p>I'm a Ph.D. Student at 
              <a href='http://hcil.snu.ac.kr/' className='text-orange-500 hover:font-bold transition-all duration-150 ease-in-out'> SNU HCI Lab</a>.</p>
              {/* , and a research intern at
              <a href='https://naver-career.gitbook.io/en/teams/clova-cic/ai-lab' className='text-green-700 hover:font-bold transition-all duration-150'> NAVER AI Lab</a>.</p> */}
            <p>My research interest is computational user interface understanding using AI.</p>
            <p>I completed my B.S. in Computer Science and Engineering and B.A. in Information Science and Culture at Seoul National University.</p>
          </div>
          {/* <div>
          {typeof window !== 'undefined' && 'email'}
          </div> */}

          <div className='rounded-sm bg-gray-100 p-1 mt-4 ring-1 ring-gray-200'>
            <p className="text-sm font-medium">SEOKHYEON.COM is under construction!</p>
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default App;
